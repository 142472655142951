import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonItem,
  IonText,
  IonButton,
  IonCard,
  IonCardContent,
  IonBadge,
  IonPage,
  IonSpinner,
} from "@ionic/react";
import { useForm } from "react-hook-form";

import "./../../theme/theme-consumer.css";
import "./TakeTesGoldsGym1.css";
import SimpleHeaderView from "../../push-components/SimpleHeaderView/SimpleHeaderView";
import { useHistory, useParams } from "react-router";
import ApplicationConfirmation from "./ApplicationConfirmation";
import ErrorScreen from "./ErrorScreen";
import SuccessScreen from "./SuccessScreens";
import {
  getModelTests,
  saveModalTaskTestQuestions,
} from "../../data-services/opportunities";

import { useDispatch } from "react-redux";
import { authActions } from "../../store/auth";
import InputTypeText from "../../push-components/InputTypes/InputTypeText";
import InputTypeSelect from "../../push-components/InputTypes/InputTypeSelect";
import InputTypeRadio from "../../push-components/InputTypes/InputTypeRadio";
import InputTypeCheckbox from "../../push-components/InputTypes/InputTypeCheckbox";
import InputTypeTextArea from "../../push-components/InputTypes/InputTypeTextArea";
import {
  showToastMessage,
  somethingWentWrongErrMsg,
  offlineErrorCode,
  unauthorizedErrorCode,
} from "../../common/common";
import NewInternalTalentReferrals from "../Referrals/NewInternalTalentReferrals/NewInternalTalentReferrals";
import {
  setOppsCache,
  invalidateOppsCache,
} from "../../data-services/opportunities";
import ExternalTalentReferrals from "../Referrals/ExternalTalentReferrals/ExternalTalentReferrals";

const TakeTesGoldsGym1: React.FC = () => {
  const tt_url_param: any = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isOpenTestConfirmModal, setIsOpenTestConfirmModal] =
    useState<boolean>(false);
  const [isOpenTestErrorModal, setIsOpenTestErrorModal] =
    useState<boolean>(false);
  const [isOpenTestSuccessModal, setIsOpenTestSuccessModal] =
    useState<boolean>(false);

  const [questionList, setQuestionList] = useState<any>([]);
  const [eventInfo, setEventInfo] = useState<any>("");
  const [eventTestInfo, setEventTestInfo] = useState<any>({});
  const [showLoading, setShowLoading] = useState<any>(false);
  const [gradedTestFailed, setGradedTestFailed] = useState<any>(false);
  const [openInternalReferral, setOpenInternalReferral] =
    useState<boolean>(false);
  const [url_send_to, setUrlSendTo] = useState<string>(`/Opportunities`);
  const [autoBookRedirectToAssignmentId, setAutoBookRedirectToAssignmentId] =
    useState<boolean>(false);

  const closeTestConfirmModal = () => {
    history.replace("/Opportunities");
  };
  const closeEcModal = () => {
    setIsOpenTestErrorModal(false);
  };
  const closeSsModal = () => {
    history.push(url_send_to);
    setIsOpenTestSuccessModal(false);
  };

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const [ref, setRef] = useState<any>("");

  const [isOpenExternalReferralModal, setIsOpenExternalReferralModal] =
    useState<boolean>(false);

  const openExternalReferralModal = async (type?: any) => {
    // Invite Contacts
    setIsOpenExternalReferralModal(true);
  };

  const closeExternalReferralModal = async () => {
    setIsOpenExternalReferralModal(false);
  };

  const getUUID = async () => {
    let uuid = localStorage.getItem('uuid');
    return uuid;
  }

  const submitTestHandler = async (e: any) => {
    let test_form: any = document.querySelector("form");
    var test_answers: any = Object.fromEntries(
      new FormData(test_form).entries()
    );

    let checkbox_selection: any;
    let checkboxes: any;

    let talent_annsers: any = {};

    // Set the talent answers
    Object.keys(test_answers).forEach(function (key) {
      if (key.startsWith("checkbox_")) {
        // Checkbox (multiple options)

        // e.g. of key value: checkbox_5-answer_1568 => answer_1568
        let splited: any = key.split("-");

        let answer_key = splited[1].split("_");
        // Answer ID
        let answer_id = answer_key[1];
        checkbox_selection = [];

        // Get the selected checkboxes only for the current answer
        checkboxes = document.querySelectorAll(
          "input[type=checkbox]:checked[name$='"+answer_id+"']"
        );
        for (var i = 0; i < checkboxes.length; i++) {
          checkbox_selection.push(checkboxes[i].value);
        }
        talent_annsers[splited[1]] = checkbox_selection;
      } else {
        // Non-Checkbox
        talent_annsers[key] = test_answers[key];
      }
    });

    // form validations start
    let no_error_found = false;
    questionList.forEach((a_question: any) => {
      a_question.requiredmessage = false; // make all error message false
    });

    questionList.forEach((a_question: any) => {
      // validation for checkbox only
      if (a_question.tq_type === "checkbox") {
        Object.keys(talent_annsers).forEach(function (key) {
          if (
            key === `answer_${a_question.tq_id}=` &&
            talent_annsers[key].length === 0
          ) {
            let splited_key: any = key.split("_");
            let ques_id: string = splited_key[1].replace(/\D/g, "");
            if (a_question.tq_id === ques_id) {
              a_question.requiredmessage = true;
              no_error_found = true;
            }
          }
        });
      } else {
        Object.keys(talent_annsers).forEach(function (key) {
          if (talent_annsers[key] === "") {
            let splited_key: any = key.split("_");
            let ques_id: string = splited_key[1].replace(/\D/g, "");
            if (a_question.tq_id === ques_id) {
              a_question.requiredmessage = true;
              no_error_found = true;
            }
          }
        });
      }
    });
    talent_annsers["ue_id"] = tt_url_param.test_event_id;
    talent_annsers["showAll"] = tt_url_param.test_type === "1" ? "1" : "";

    if (tt_url_param?.pending_events_ids) {
      talent_annsers["pending_events_ids"] = tt_url_param.pending_events_ids;
    }

    if (tt_url_param?.declined_events_ids) {
      talent_annsers["declined_events_ids"] = tt_url_param.declined_events_ids;
    }

    if (tt_url_param?.auto_book) {
      talent_annsers["auto_book"] = tt_url_param?.auto_book;
      if (tt_url_param?.pending_events_ids) {
        if (typeof tt_url_param.pending_events_ids[0] !== "undefined") {
          setAutoBookRedirectToAssignmentId(tt_url_param.pending_events_ids[0]);
        } else {
          setAutoBookRedirectToAssignmentId(tt_url_param.test_event_id);
        }
      } else {
        setAutoBookRedirectToAssignmentId(tt_url_param.test_event_id);
      }
    }

    talent_annsers["ct_sign_up_test"] = "0";
    talent_annsers["ref"] = ref;
    talent_annsers["uuid"] = localStorage.getItem('uuid');
    
    if (!no_error_found) {
      let model_test = await saveModalTaskTestQuestions(talent_annsers);

      if (model_test?.success) {
        if (
          (model_test.result === "Passed" && tt_url_param.test_type !== "1") ||
          model_test.gradedTest === "0"
        ) {
          history.push('/PulseStepUploadPhotoGoldsGym1');
          // setIsOpenTestConfirmModal(true);
        } else if (model_test.result === "Failed") {
          setGradedTestFailed(
            model_test.gradedTest == "1" && model_test.remainingAttempts == 0
          );
          setIsOpenTestErrorModal(true);

          takeTestInit();
        } else {
          setIsOpenTestSuccessModal(true);
        }
        // Refresh the available opportunity cache
        await invalidateOppsCache();
        let opps;
        if (model_test?.cachedOpps?.cachedOppsAvailable) {
          try {
            opps = JSON.parse(model_test.cachedOpps.cachedOppsAvailable);
            await setOppsCache(
              {
                success: true,
                message: "",
                data: { model_opportunities: opps.opportunitiesList },
              },
              "Available"
            );
          } catch (e) {
            // Do nothing, invalid or empty response
          }
        }
        if (model_test?.cachedOpps?.cachedOppsPending) {
          try {
            opps = JSON.parse(model_test.cachedOpps.cachedOppsPending);
            await setOppsCache(
              {
                success: true,
                message: "",
                data: { model_opportunities: opps.opportunitiesList },
              },
              "Pending"
            );
          } catch (e) {
            // Do nothing, invalid or empty response
          }
        }
        if (model_test?.cachedOpps?.cachedOppsDeclined) {
          try {
            opps = JSON.parse(model_test.cachedOpps.cachedOppsDeclined);
            await setOppsCache(
              {
                success: true,
                message: "",
                data: { model_opportunities: opps.opportunitiesList },
              },
              "Declined"
            );
          } catch (e) {
            // Do nothing, invalid or empty response
          }
        }
        if (model_test?.cachedOpps?.cachedOppsNearby) {
          try {
            opps = JSON.parse(model_test.cachedOpps.cachedOppsNearby);
            await setOppsCache(
              {
                success: true,
                message: "",
                data: { model_opportunities: opps.opportunitiesList },
              },
              "Nearby"
            );
          } catch (e) {
            // Do nothing, invalid or empty response
          }
        }
      } else if (model_test?.data?.errorCode == unauthorizedErrorCode) {
        dispatch(authActions.logout());
        return;
      } else if (model_test?.data?.errorCode == offlineErrorCode) {
        showToastMessage(model_test?.data?.message);
      } else if (model_test?.message) {
        showToastMessage(model_test.message);
      } else {
        showToastMessage(somethingWentWrongErrMsg);
      }
    }
  };
  const openNewInternalModal = () => {
    setOpenInternalReferral(true);
  };
  const closeNewInernalModal = () => {
    setOpenInternalReferral(false);
  };

  const takeTestInit = async () => {
    setShowLoading(true);
    let model_test = await getModelTests(
      tt_url_param.test_event_id,
      tt_url_param.test_type
    );
    if (model_test?.success) {
      let test_details: any = model_test.tests[0].questions;
      test_details.forEach((ele: any) => {
        ele.requiredmessage = false;
      });
      setQuestionList(test_details);
      setEventInfo(model_test.event);
      model_test.tests[0].ct_allowed_fails =
        model_test.tests[0].ct_allowed_fails == 0
          ? 1
          : model_test.tests[0].ct_allowed_fails;
      setEventTestInfo(model_test.tests[0]);
      setGradedTestFailed(
        model_test.tests[0].ct_type == "2" &&
          model_test.tests[0].mt_status == "Failed" &&
          Number(model_test.tests[0].mt_attempts_left) == 0
      );
      setUrlSendTo(
        model_test.tests[0].ct_after_booking == "0"
          ? `/Opportunities`
          : `/Assignments`
      );

      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const refParam = urlParams.get("ref");

      if (refParam) {
        setRef(refParam);
      }
    } else if (model_test?.data?.errorCode == unauthorizedErrorCode) {
      dispatch(authActions.logout());
      return;
    } else if (model_test?.data?.errorCode == offlineErrorCode) {
      showToastMessage(model_test?.data?.message);
    } else if (model_test?.message) {
      // Redirect to Opportunities if, for some reason, test can not be accessed (already completed scenario)
      showToastMessage(model_test.message);
      history.push(`/Opportunities`);
    } else {
      // Redirect to Opportunities if, for some reason, test can not be accessed (already completed scenario)
      showToastMessage(somethingWentWrongErrMsg);
      history.push(`/Opportunities`);
    }
    setShowLoading(false);
  };

  useEffect(() => {
    (async () => {
      await takeTestInit();
    })();
  }, []);
  return (
    <IonPage>
      {/* <SimpleHeaderView
        title="Questionnaire"
        bgcolor="primary"
        buttonType=""
      ></SimpleHeaderView> */}
      <IonContent className={`lay__talent-signup--container--goldsgym1 lay__taketest`}>
        {/* <div className="lay__lt">
          <img src="/images/cons/cp1/icon-l1.png" />
        </div>
        <div className="lay__rt">
          <img src="/images/cons/cp1/icon-r1.png" />
        </div>
        <div className="lay__lb">
          <img src="/images/cons/cp1/icon-lb1.png" />
        </div>
        <div className="lay__rb">
          <img src="/images/cons/cp1/icon-rb1.png" />
        </div> */}

        <div className={`lay__talent-signup--goldsgym1`}>
          <div className="lay__logo">
            <img src="/images/cons/goldsgym/logo.png" />
          </div>

        {false && showLoading && (
          <div className="slider-card_spinner">
            <IonSpinner color="primary" name="crescent" />
          </div>
        )}

        {/* {qrDetails?.signupDetails?.snpId === 1 &&  */}
        {true && 
          <div className="sn__id1">
            <div className="talent-signup__section-title">
              <img style={{paddingTop: '20px'}} src="/images/cons/goldsgym/WELCOME.png" />
            </div>
            <div className="talent-signup__section-title">
              <img style={{paddingTop: '20px'}} src="/images/cons/goldsgym/test_subtitle.png" />
            </div>
          </div>
        }

        {!showLoading && (
          // <div className={`lay__talent-signup--container--${qrDetails?.theme} take-tast-content`} >
          <div className={`take-tast-content`} >

            {!gradedTestFailed && (
              <form onSubmit={handleSubmit(submitTestHandler)}>
                {questionList &&
                  questionList.map((question: any, index: number) => (
                    <div key={index}>
                      {question.tq_type === "text" && (
                        <InputTypeText
                          question={question.tq_question}
                          question_id={question.tq_id}
                          questionno={index + 1}
                          requiredmessage={question.requiredmessage}
                        />
                      )}
                      {question.tq_type === "textarea" && (
                        <InputTypeTextArea
                          question={question.tq_question}
                          question_id={question.tq_id}
                          options={question.tq_options}
                          questionno={index + 1}
                          requiredmessage={question.requiredmessage}
                        />
                      )}
                      {question.tq_type === "checkbox" && (
                        <InputTypeCheckbox
                          question={question.tq_question}
                          question_id={question.tq_id}
                          options={question.tq_options}
                          questionno={index + 1}
                          requiredmessage={question.requiredmessage}
                          answer={[]}
                        />
                      )}
                      {question.tq_type === "radio" && (
                        <InputTypeRadio
                          question={question.tq_question}
                          question_id={question.tq_id}
                          options={question.tq_options}
                          questionno={index + 1}
                          requiredmessage={question.requiredmessage}
                        />
                      )}
                      {question.tq_type === "select" && (
                        <InputTypeSelect
                          question={question.tq_question}
                          question_id={question.tq_id}
                          options={question.tq_options}
                          questionno={index + 1}
                          key={index}
                          requiredmessage={question.requiredmessage}
                        />
                      )}
                    </div>
                  ))}
                <div className="take-test-question__submit-btn">
                  <IonButton
                    expand="block"
                    mode="ios"
                    type="submit"
                    className="form-group__button"
                    // onClick={submitTestHandler}
                  >
                    Submit
                  </IonButton>
                </div>
              </form>
            )}
          </div>
        )}
      </div>
      </IonContent>
      <ApplicationConfirmation
        isopen_ac_modal={isOpenTestConfirmModal}
        closeAcModal={closeTestConfirmModal}
        event_name={eventInfo?.ue_name}
        event_id={tt_url_param?.test_event_id}
        event_application_confirmation_photo={
          eventInfo?.application_confirmation_photo
        }
        openNewInternalModal={openNewInternalModal}
        autoBookRedirectToAssignmentId={autoBookRedirectToAssignmentId}
        event_referral_link={eventInfo?.referral_link}
        event_city={eventInfo?.ue_nearest_city}
        openExternalReferralModal = {openExternalReferralModal}
      />

      <ErrorScreen
        isopen_es_modal={isOpenTestErrorModal}
        gradedTestFailed={gradedTestFailed}
        closeEcModal={closeEcModal}
      />
      <SuccessScreen
        isopen_ss_modal={isOpenTestSuccessModal}
        closeSsModal={closeSsModal}
        redirect_to={url_send_to}
      />
    </IonPage>
  );
};
export default TakeTesGoldsGym1;
