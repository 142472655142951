import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useDispatch, useStore } from "react-redux";
import {
  IonButton,
  IonContent,
  IonPage,
} from "@ionic/react";

import "./../../theme/theme-consumer.css";
import "./PulseStepShareGoldsGym1.css";
import { useForm } from "react-hook-form";
import {
  getModelProfileData,
} from "../../data-services/edit-profile";

import {
  showToastMessage,
  somethingWentWrongErrMsg,
  unauthorizedErrorCode,
  offlineErrorCode,
  unauthorizedErrMsg,
  getQrCodeInfo,
} from "../../common/common";

const PulseStepShareGoldsGym1: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [photoFileName, setPhotoFileName] = useState('');

  const getTalentPhoto = async () => {
    let profile_data: any = await getModelProfileData();
    console.log(profile_data);
    
    setPhotoFileName(profile_data?.data?.photos?.model_headshot);

    if (!profile_data?.success) {
      if (profile_data?.data?.errorCode == 0) {
        
        showToastMessage(profile_data?.data?.message);
        return;
      } else {
        // dispatch(authActions.logout());
        return;
      }
    }
    profile_data = profile_data.data;
  }

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });
  
  const submitTestHandler = async (e: any) => {
    history.push('/PulseStepShare');
  };

  const [qrDetails, setQrDetails] = useState<any>();
  const populateQrCodeInfo = () => {
    let qrDetails = getQrCodeInfo();
    setQrDetails(qrDetails);
  }

  useEffect(() => {
    populateQrCodeInfo();
    getTalentPhoto();

  }, []);


  return (
    <IonPage>

      <IonContent className={`lay__talent-signup--container--${qrDetails?.themeShort} step-share-selfie`}>
        {typeof qrDetails != 'undefined' && 
          <div className={`lay__talent-signup--${qrDetails?.themeShort}`}>
            <div className="lay__logo">
              <img src="/images/cons/goldsgym/logo.png" />
            </div>

            {/* <div className="lay__lt" style={{top: '280px'}}>
              <img style={{height: '130px'}}  src="/images/cons/AZLottery/moneytransparent.gif" />
            </div> */}

            {/* <div className="lay__lt" style={{top: '480px'}}>
              <img style={{height: '190px'}} src="/images/cons/cp1/icon4-lb.png" />
            </div>
            <div className="lay__rt" style={{top: '200px'}}>
              <img style={{height: '150px'}} src="/images/cons/cp1/icon4-rt.png" />
            </div>
            <div className="lay__rt" style={{top: '400px'}}>
              <img style={{height: '190px'}} src="/images/cons/cp1/icon4-rm.png" />
            </div> */}

            {/* <div className="lay__bottom-bar">
              <img src="/images/cons/cp1/bottom-bar--bluebg.png" />
            </div> */}

            {true && 
              <div className="sn__id1" style={{maxWidth: '250px', marginLeft: 'auto', marginRight: 'auto'}}>
                <div className="">
                  <img style={{marginTop: '25px'}} src="/images/cons/goldsgym/PERFECT.png" />
                </div>
                <div className="">
                  <img style={{marginTop: '25px'}} src="/images/cons/goldsgym/look_great.png" />
                </div>
              </div>
            }

            {true && 
              <div className="" style={{maxWidth: '250px', marginLeft: 'auto', marginRight: 'auto', marginTop: '40px'}}>
                <div className="consumer-uploaded-photo-container">
                  {photoFileName && <img className="consumer-uploaded-photo" src={`${photoFileName}`} />}
                  <img style={{marginTop: '20px', width: '100%'}} src="/images/cons/goldsgym/IG frame.png" />
                </div>
              </div>
            }

            {true && 
              <div className="" style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '40px'}}>
                <div className="">
                  <img style={{marginTop: '20px', maxWidth: '350px', marginLeft: 'auto', marginRight: 'auto'}} src="/images/cons/goldsgym/show_this.png" />
                </div>
              </div>
            }

              {/* <div className="">
                <IonButton
                  expand="block"
                  mode="ios"
                  type="submit"
                  className="form-group__button actionlink__cta"
                  onClick={() => {window.location.href='https://instagram.com'}}
                >
                  Go to Instagram
                </IonButton>
              </div> */}
          </div>
        }
      </IonContent>
      
    </IonPage>
  );
};

export default PulseStepShareGoldsGym1;