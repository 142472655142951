import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonPage,
} from "@ionic/react";
import "./Landing.css";
import { useHistory, useParams } from "react-router";
import { authActions } from './../../store/auth'
import { useDispatch } from 'react-redux'
import { CapacitorHttp } from "@capacitor/core";
import { get, set } from "../../storage/storage";

const LandingConsumer: React.FC<any> = () => {
  const [loading, setLoading] = useState(true);
  const url_params: any = useParams();
  const qrCode = url_params?.qr_code;
  const history = useHistory();
  const dispatch = useDispatch();

  const checkQRCode = async () => {
    

    if (typeof qrCode != 'undefined') {

      let res = await getQrCodeInfo();

      if (res.data?.success) {

        if (res.data?.data?.redirectTo) {
          window.location.href = res.data?.data?.redirectTo;
          return;
        }

        if (res.data?.data?.has_active_event) {
          localStorage.setItem('qrInfo', JSON.stringify(res.data?.data));
          
          if (res.data?.data?.theme == 'azlottery1') {
            history.push('/Signup_azlottery1');
            return;
          }

          if (res.data?.data?.theme == 'sparklingice1') {
            history.push('/Signup_sparklingice1');
            return;
          }
          if (res.data?.data?.theme == 'img__goldsgym1.png') {
            history.push('/Signup_goldsgym1');
            return;
          }
          
          history.push('/Signup');
          return;
        } else {
          window.location.href = 'https://pushmodels.com/'+res.data?.data?.model_site_url;
          return;
        }
      }
    }

    setLoading(false);
  }

  const generateUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
  }

  const getUUID = async () => {
    let uuid = localStorage.getItem('uuid');
    if (!uuid) {
      let uuidgen = generateUUID();
      localStorage.setItem('uuid',uuidgen);
      return uuidgen;
    }
    return uuid;
  }

  const getQrCodeInfo = async () => {
    let uuid = await getUUID();
    let config: any = {
      url: encodeURI(
        `${process.env.REACT_APP_HP_API_URL}/users/qrCodeInfo?qrCode=${qrCode}&uuid=${uuid}`
      ),
      headers: {
        "Content-Type": "application/json",
      },
    };

    console.log(config);

    let qrCodeInfoRes = await CapacitorHttp.get(config);
    return qrCodeInfoRes;
  }

  useEffect(() => {
    (async () =>  {
      await checkQRCode();
    })();
    
  }, []);

  return (
    <>
      <IonPage>
        <IonContent scroll-y="false">
          <div className="landing__container">
            <div className="landing__container-inner-shadow"></div>
            <div style={{height: '100%', display: 'flex', flexDirection:'column', alignItems: 'center', justifyContent: 'center'}}>
              {!loading && <img style={{width: '80%', maxWidth: '600px'}} src="/images/push-pulse-logo-01.png" />}
            </div>
          </div>
        </IonContent>
      </IonPage>
    </>
  );
};

export default LandingConsumer;
