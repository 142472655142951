import { Route, Redirect, Switch, useHistory } from "react-router-dom";
import { IonReactRouter } from "@ionic/react-router";
import React, { useEffect, useState } from "react";
import Favourite from "./pages/Favourite/Favourite";
import { get } from "./storage/storage";
import {
  IonRouterOutlet,
  IonApp,
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonIcon,
} from "@ionic/react";
import {
  personCircle,
  home,
  calendarClearOutline,
  starOutline,
  logoUsd,
} from "ionicons/icons";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./assets/fonts/OpenSans/font-face.css";
import "./assets/fonts/GaramondRegular/font-face.css";
import "./assets/fonts/Prata/font-face.css";
import "./assets/fonts/CenturyGothic/font-face.css";
import "./assets/fonts/BalooThambiRegular/font-face.css";
import "./assets/fonts/CenturySchoolbook/font-face.css";
import "./assets/fonts/FontAwesome/font-face.css";
import "./assets/fonts/BebasNeue/font-face.css";
import "./assets/fonts/BodoniStd/font-face.css";
import "./assets/fonts/AltGothicATF/font-face.css";
import "./theme/variables.css";
import "./theme/theme.css";

/* Custom imports */
import Login from "./pages/Users/Login/Login";
import Forgot from "./pages/Users/Forgot/Forgot";
import Signup from "./pages/Users/Signup/Signup";
import LandingConsumer from "./pages/Landing/LandingConsumer";
import Home from "./pages/Home/Home";
import MyCalendar from "./pages/MyCalendar/MyCalendar";

import { useSelector } from "react-redux";
import { isUserloggedIn } from "./store/auth";
import { useDispatch } from "react-redux";
import BecomeAmbassador from "./pages/BecomeAmbassador/BecomeAmbassador";
import Notifications from "./pages/Notifications/Notifications";
import BecomeModel from "./pages/BecomeModel/BecomeModel";
import BecomeInfluencer from "./pages/BecomeInfluencer/BecomeInfluencer";
import ReferFriend from "./pages/ReferFriend/ReferFriend";
import AmbassadorForm from "./pages/BecomeAmbassador/AmbassadorForm";
import ModalForm from "./pages/BecomeModel/ModalForm";
import EditProfile from "./pages/Users/Profile/EditProfile";
import InfluencerForm from "./pages/BecomeInfluencer/InfluencerForm";
import MyProfile from "./pages/Users/MyProfile/MyProfile";
import ExternalTalentReferralsOptions from "./pages/Referrals/ExternalTalentReferrals/ExternalTalentReferralsOptions";
import MySettings from "./pages/Users/MySettings/MySettings";
import ChangePassword from "./pages/Users/ChangePassword/ChangePassword";
import Opportunities from "./pages/Opportunities/Opportunities";
import OpportunitiesView from "./pages/OpportunitiesView/OpportunitiesView";
import ProfileView from "./pages/ProfileView/ProfileView";

import AppNotification from "./pages/AppNotification/AppNotification";
import Payments from "./pages/Payments/Payments";
import PaidRevenue from "./pages/Payments/PaidRevenue";
import PendingRevenue from "./pages/Payments/PendingRevenue";
import NotificationDashBoard from "./pages/Users/NotificationDashBoard/NotificationDashBoard";
import NotificationMarket from "./pages/Users/NotificationDashBoard/NotificationMarket";
import NotificationRate from "./pages/Users/NotificationDashBoard/NotificationRate";
import NotificationFrequency from "./pages/Users/NotificationDashBoard/NotificationFrequency";
import NotificationType from "./pages/Users/NotificationDashBoard/NotificationType";

import FAQs from "./pages/Users/FAQs/FAQs";
import FAQsProfileManagement from "./pages/Users/FAQs/FAQsProfileManagament";
import ChatList from "./pages/Chat/ChatList/ChatList";
import Chat from "./pages/Chat/ChatView/Chat";
import Assignments from "./pages/Assignments/Assignments";
// import Rating from "./pages/Rating/Rating";
import AssignmentView from "./pages/AssignmentView/AssignmentView";
import TakeTest from "./pages/TakeTest/TakeTest";
import TakeTestAZLottery1 from "./pages/TakeTest/TakeTestAZLottery1";
import TakeTesGoldsGym1 from "./pages/TakeTest/TakeTesGoldsGym1";
import AssignmentRecap from "./pages/AssignmentRecap/AssignmentRecap";
import MyRewards from "./pages/MyRewards/MyRewards";
import RewardCategory from "./pages/MyRewards/RewardCategory";
import RewardHistory from "./pages/MyRewards/RewardHistory";
import MyPoints from "./pages/Favourite/MyPoints";
import Badges from "./pages/Badges/Badges";
import Referrals from "./pages/Referrals/Referrals";
import MyCrew from "./pages/Users/MyCrew/MyCrew";
import ManageIntroVideo from "./pages/Users/ManageIntroVideo/ManageIntroVideo";
import PaymentDetails from "./pages/Users/PaymentDetails/PaymentDetails";

import PulseStepUploadPhoto from "./pages/PushPulse/PulseStepUploadPhoto";
import PulseStepUploadPhotoAZLottery1 from "./pages/PushPulse/PulseStepUploadPhotoAZLottery1";
import PulseStepUploadPhotoGoldsGym1 from "./pages/PushPulse/PulseStepUploadPhotoGoldsGym1";
import PulseStepShare from "./pages/PushPulse/PulseStepShare";
import PulseStepShareAZLottery1 from "./pages/PushPulse/PulseStepShareAZLottery1";
import PulseStepShareGoldsGym1 from "./pages/PushPulse/PulseStepShareGoldsGym1";

import { PushNotifications } from "@capacitor/push-notifications";
import AppUrlListener from "./components/AppUrlListener";
import Signup_sparklingice1 from "./pages/Users/Signup/Signup_sparklingice1";
import Signup_azlottery1 from "./pages/Users/Signup/Signup_azlottery1";
import Signup_goldsgym1 from "./pages/Users/Signup/Signup_goldsgym1";

const App: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isTalentOnSingup, setIsTalentOnSingup] = useState(false);
  const [isWebsitePage, setIsWebsitePage] = useState(false);
  const isAuthenticated = useSelector(
    (state: any) => state.auth.is_authenticated
  );

  /**
   * Check if the route is accessible only to authenticated users.
   * Note that some routes (from signup) are both public and private. See isSignUpFlowAppRoute.
   * @returns boolean
   */
  const isProtectedAppRoute = () => {
    let routeName = window.location.pathname.toLowerCase();
    if (
      routeName !== "/" &&
      !routeName.startsWith("/Landing".toLowerCase(), 0) &&
      !routeName.startsWith("/Login".toLowerCase(), 0) &&
      !routeName.startsWith("/Signup".toLowerCase(), 0) &&
      !routeName.startsWith("/Forgot".toLowerCase(), 0) &&
      !routeName.startsWith("/MySettings".toLowerCase(), 0)
    ) {
      return true;
    }
    return false;
  };

  /**
   * Check if it's a signup route EXCEPT /SignUp.
   * @returns 
   */
  const isSignUpFlowAppRoute = () => {
    // check user in following routes
    let signupRoutes = window.location.pathname.toLowerCase();
    let isSingupRoute = false;
    if (
      signupRoutes.startsWith("/BecomeAmbassador".toLowerCase(), 0) ||
      signupRoutes.startsWith("/AmbassadorForm".toLowerCase(), 0) ||
      signupRoutes.startsWith("/Notifications".toLowerCase(), 0) ||
      signupRoutes.startsWith("/BecomeModel".toLowerCase(), 0) ||
      signupRoutes.startsWith("/ModalForm".toLowerCase(), 0) ||
      signupRoutes.startsWith("/BecomeInfluencer".toLowerCase(), 0) ||
      signupRoutes.startsWith("/InfluencerForm".toLowerCase(), 0) ||
      signupRoutes.startsWith("/ReferFriend".toLowerCase(), 0)
    ) {
      isSingupRoute = true;
    }
    return isSingupRoute;
  };

  useEffect(() => {

    if (process.env?.REACT_APP_IS_TALENT_PORTAL) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const refParam = urlParams.get('ref');
      let redirectUrl = 'https://pushmodels.com/model_personal_detail.php?fromAutoNotif=true';

      if (refParam) {
        redirectUrl += `&ref=${refParam}`;
      }

      window.location.href = redirectUrl;
    }

    (async () => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      if(urlParams.get('ltoken')) {
        localStorage.clear();
      }
      if (isSignUpFlowAppRoute()) {
        setIsTalentOnSingup(true);
      } else {
      }
      dispatch(isUserloggedIn());

      if (process.env?.REACT_APP_IS_NATIVE) {
        // Method called when tapping on a notification
        PushNotifications.addListener(
          "pushNotificationActionPerformed",
          (notification) => {
            const data = notification?.notification?.data; // assuming the data contains a field 'url'
            if (data?.route) {
              window.location.assign(data.route);
            }
          }
        );
      }
    })();
  }, [dispatch]);

  return (
    <IonApp>
      {/* Login layout (when a Protected Route is accessed but user is logged out) */}
      {
        // Application route but unauthenticated, load login
        !isSignUpFlowAppRoute() && isProtectedAppRoute() && !isAuthenticated
        ? (
          <IonReactRouter>
            <AppUrlListener></AppUrlListener>
            <IonRouterOutlet id="main" animated={false} mode="md">
              <Switch>
                <Route exact path="/Signup_azlottery1">
                  <Signup_azlottery1 />
                </Route>
                <Route exact path="/Signup_sparklingice1">
                  <Signup_sparklingice1 />
                </Route>
                <Route exact path="/Signup_goldsgym1">
                  <Signup_goldsgym1 />
                </Route>
                
                <Route exact path="/Signup">
                  <Signup />
                </Route>
                <Route exact path="/qr/:qr_code">
                  <LandingConsumer />
                </Route>
                <LandingConsumer />
              </Switch>
            </IonRouterOutlet>
          </IonReactRouter>
        ) : (
          <>
            {isSignUpFlowAppRoute() && isAuthenticated ? (
              <IonReactRouter>
                <AppUrlListener></AppUrlListener>
                <IonRouterOutlet id="main" animated={false} mode="md">
                  <Switch>
                    <Route exact path="/Notifications/:talent_signup_id">
                      <Notifications />
                    </Route>
                    <Route exact path="/BecomeAmbassador/:talent_signup_id">
                      <BecomeAmbassador />
                    </Route>
                    <Route exact path="/AmbassadorForm/:talent_signup_id">
                      <AmbassadorForm />
                    </Route>
                    <Route exact path="/BecomeModel/:talent_signup_id">
                      <BecomeModel />
                    </Route>
                    <Route exact path="/ModalForm/:talent_signup_id">
                      <ModalForm />
                    </Route>
                    <Route exact path="/BecomeInfluencer/:talent_signup_id">
                      <BecomeInfluencer />
                    </Route>
                    <Route exact path="/InfluencerForm/:talent_signup_id">
                      <InfluencerForm />
                    </Route>
                    <Route exact path="/ReferFriend/:talent_signup_id">
                      <ReferFriend />
                    </Route>
                    <Redirect to="/Home" />
                  </Switch>
                </IonRouterOutlet>
              </IonReactRouter>
            ) : (
              <>
                {/* Website Home layout */}
                {!isSignUpFlowAppRoute() && isAuthenticated ? (
                  <IonReactRouter>
                    <AppUrlListener></AppUrlListener>
                    <IonTabs>
                      <IonRouterOutlet id="main" animated={false} mode="md">
                        <Switch>
                          <Route exact path="/PulseStepUploadPhoto">
                            <PulseStepUploadPhoto />
                          </Route>
                          <Route exact path="/PulseStepUploadPhotoAZLottery1">
                            <PulseStepUploadPhotoAZLottery1 />
                          </Route>
                          <Route exact path="/PulseStepUploadPhotoGoldsGym1">
                            <PulseStepUploadPhotoGoldsGym1 />
                          </Route>
                          <Route exact path="/PulseStepShare">
                            <PulseStepShare />
                          </Route>
                          <Route exact path="/PulseStepShareAZLottery1">
                            <PulseStepShareAZLottery1 />
                          </Route>
                          <Route exact path="/PulseStepShareGoldsGym1">
                            <PulseStepShareGoldsGym1 />
                          </Route>
                          <Route exact path="/Home">
                            <Home />
                          </Route>
                          <Route exact path="/MyCalendar">
                            <Assignments />
                          </Route>
                          <Route exact path="/MyScoreboard/:display?">
                            <Favourite />
                          </Route>
                          <Route exact path="/MyAccount">
                            <MyProfile />
                          </Route>
                          <Route exact path="/EditProfile">
                            <EditProfile />
                          </Route>
                          <Route exact path="/MyProfile">
                            <MyProfile />
                          </Route>
                          <Route exact path="/ExternalTalentReferralsOptions">
                            <ExternalTalentReferralsOptions />
                          </Route>
                          <Route exact path="/MySettings">
                            <MySettings />
                          </Route>
                          <Route exact path="/ChangePassword">
                            <ChangePassword />
                          </Route>
                          <Route exact path="/MySettings">
                            <MySettings />
                          </Route>
                          <Route exact path="/FAQs">
                            <FAQs />
                          </Route>
                          <Route exact path="/FAQsProfileManagement/:id">
                            <FAQsProfileManagement />
                          </Route>
                          <Route exact path="/PaymentDetails">
                            <PaymentDetails />
                          </Route>
                          <Route exact path="/Referrals">
                            <Referrals />
                          </Route>
                          <Route exact path="/Opportunities">
                            <Opportunities />
                          </Route>

                          <Route exact path="/OpportunitiesView/:event_id">
                            <OpportunitiesView />
                          </Route>
                          <Route exact path="/Opp/:event_id/:ref?">
                            <OpportunitiesView />
                          </Route>
                          <Route exact path="/ProfileView">
                            <ProfileView />
                          </Route>
                          <Route exact path="/MyRewards">
                            <MyRewards />
                          </Route>
                          <Route exact path="/RewardCategory/:category_id/:reward_id?">
                            <RewardCategory />
                          </Route>
                          <Route exact path="/RewardHistory">
                            <RewardHistory />
                          </Route>
                          <Route exact path="/AppNotification">
                            <AppNotification />
                          </Route>
                          <Route exact path="/Payments">
                            <Payments />
                          </Route>
                          <Route exact path="/PaidRevenue">
                            <PaidRevenue />
                          </Route>
                          <Route exact path="/PendingRevenue">
                            <PendingRevenue />
                          </Route>
                          <Route exact path="/PendingRevenue">
                            <PendingRevenue />
                          </Route>
                          <Route exact path="/NotificationDashBoard">
                            <NotificationDashBoard />
                          </Route>
                          <Route exact path="/NotificationMarket">
                            <NotificationMarket />
                          </Route>
                          <Route exact path="/NotificationRate">
                            <NotificationRate />
                          </Route>
                          <Route exact path="/NotificationFrequency">
                            <NotificationFrequency />
                          </Route>
                          <Route exact path="/NotificationType">
                            <NotificationType />
                          </Route>
                          <Route exact path="/Chat/:receiverid">
                            <Chat />
                          </Route>
                          <Route exact path="/ChatList">
                            <ChatList />
                          </Route>
                          <Route exact path="/Assignments">
                            <Assignments />
                          </Route>
                          <Route
                            exact
                            path="/AssignmentView/:assign_event_id"
                          >
                            <AssignmentView />
                          </Route>
                          <Route exact path="/AssignmentRecap/:eventid">
                            <AssignmentRecap />
                          </Route>

                          <Route exact path="/MyCrew">
                            <MyCrew />
                          </Route>

                          <Route exact path="/ManageIntroVideo">
                            <ManageIntroVideo />
                          </Route>

                          <Route exact path="/MyPoints">
                            <MyPoints />
                          </Route>
                          <Route exact path="/Badges">
                            <Badges />
                          </Route>
                          <Route
                            exact
                            path="/TakeTest/:test_event_id/:test_type/:auto_book?"
                          >
                            <TakeTest />
                          </Route>

                          <Route
                            exact
                            path="/TakeTestAZLottery1/:test_event_id/:test_type/:auto_book?"
                          >
                            <TakeTestAZLottery1 />
                          </Route>

                          <Route
                            exact
                            path="/TakeTesGoldsGym1/:test_event_id/:test_type/:auto_book?"
                          >
                            <TakeTesGoldsGym1 />
                          </Route>

                          <Route
                            exact
                            path="/TakeTestMultiday/:test_event_id/:test_type/:pending_events_ids?/:declined_events_ids?/:auto_book?"
                          >
                            <TakeTest />
                          </Route>
                          <Route exact path="/qr/:qr_code">
                            <LandingConsumer />
                          </Route>
                          <Route exact path="/LandingConsumer">
                            <LandingConsumer />
                          </Route>
                          <Redirect to="/LandingConsumer" />
                          
                        </Switch>
                      </IonRouterOutlet>
                      <IonTabBar slot="bottom">
                        {/* <IonTabButton href="/Home" tab="home">
                          <IonIcon icon={home} />
                        </IonTabButton>

                        <IonTabButton tab="calendar" href="/MyCalendar">
                          <IonIcon icon={calendarClearOutline} />
                        </IonTabButton>

                        <IonTabButton tab="star" href="/MyScoreboard">
                          <IonIcon icon={starOutline} />
                        </IonTabButton>
                        <IonTabButton tab="payments" href="/Payments">
                          <IonIcon icon={logoUsd} />
                        </IonTabButton>

                        <IonTabButton href="/MyAccount" tab="my-account">
                          <IonIcon icon={personCircle} />
                        </IonTabButton> */}
                      </IonTabBar>
                    </IonTabs>
                  </IonReactRouter>
                ) : (
                  <>
                    <IonReactRouter>
                      <AppUrlListener></AppUrlListener>
                      <IonRouterOutlet id="main" animated={false} mode="md">
                        <Switch>
                          <Route exact path="/Login">
                            <Login />
                          </Route>
                          <Route exact path="/Signup">
                            <Signup />
                          </Route>
                          <Route exact path="/Forgot">
                            <Forgot />
                          </Route>
                          <Route exact path="/qr/:qr_code">
                            <LandingConsumer />
                          </Route>
                          <LandingConsumer />
                        </Switch>
                      </IonRouterOutlet>
                    </IonReactRouter>
                  </>
                )}
              </>
            )}
          </>
        )
      }


    </IonApp>
  );
};

export default App;
